<template>
  <div
    class="product"
    v-if="orderProduct"
  >
    <template
      v-for="(m, i) in orderProduct"
      :key="`pro${i}`"
    >
      <template v-if="m.product">
        <div
          class="item flex align-items-c"
        >
          <div class="img">
            <img
              :src="m.product.cart_info.product.image + '!120a'"
              alt=""
            >
          </div>
          <div class="desc">
            <div class="name fw500 ft12"><span v-if="m.product.cart_info.product.lot" class="mr5">LOT{{ m.product.cart_info.product.lot }} </span>{{ m.product.cart_info.product.store_name }}</div>
            <div class="price">
              <span>落槌价 <b>{{ formatPriceNumber(Number(m.product.product_price), 2) }}</b></span>
              <span>佣金 <b>{{ formatPriceNumber(Number(m.product.commission_price), 2) }}</b></span>
              <span>
                <template v-if="m.product.cart_info.product.postage_type == 2">运费 {{ formatPriceNumber(Number(m.product.cart_info.product.postage_price), 2) }}</template>
                <template v-else-if="m.product.cart_info.product.postage_type == 3">包邮</template>
                <template v-else-if="m.product.cart_info.product.postage_type == 4">到付</template>
              </span>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { formatPriceNumber } from '@/utils/util';

export default {
  props: {
    orderProduct: [Array, String],
  },
  setup() {
    return {
      formatPriceNumber
    }
  },
};
</script>

<style lang="scss" scoped>
.product {
  background: #fff;
  padding: 32px;
  .item {
    border-top: 2px solid #eeeeee;
    padding-top: 20px;
    margin-top: 20px;
    &:first-child {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }
    .img {
      width: 128px;
      height: 128px;
      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 8px;
      }
    }

    .desc {
      flex: 1;
      margin-left: 18px;
      overflow: hidden;
      color: #000;

      .name {
        line-height: 35px;
        height: 70px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .price {
        margin-top: 14px;
        color: #999999;
        span {
          margin-right: 10px;
        }
      }

      .price-b {
        margin-top: 8px;

        b {
          margin-left: 6px;
        }
      }
    }
  }
}
</style>