<template>
  <div class="top-s">
    <div class="status">
      <div class="status-t fw500">
        <template v-if="refundData.status == 0">待审核</template>
        <template v-else-if="refundData.status == -1">审核未通过</template>
        <template v-else-if="refundData.status == -2">已关闭</template>
        <template v-else-if="refundData.status == 1">待买家退货</template>
        <template v-else-if="refundData.status == 2">待卖家收货</template>
        <template v-else-if="refundData.status == 3">已售后完成</template>
      </div>
      <div
        class="status-prop"
        v-if="(refundData.status == -1 || refundData.status == -2) && refundData.fail_message"
      >{{ refundData.fail_message }}</div>
      <div class="status-txt">
        <div class="bg"></div>
        <div class="content flex align-items-c">
          <div class="item">
            <div class="bg-l"></div>
            <div class="img item-flex-center">
              <img
                src="https://artimg2.artart.cn/image/20220223/f50d059d34dc07c570052bdc3c6b9fc6.png"
                alt=""
              >
            </div>
            <div class="txt">提交申请</div>
          </div>
          <div
            v-if="refundData.status == -2"
            class="item"
          >
            <div class="img item-flex-center">
              <img
                src="https://artimg2.artart.cn/image/20220223/f50d059d34dc07c570052bdc3c6b9fc6.png"
                alt=""
              >
            </div>
            <div class="txt">取消售后</div>
          </div>
          <template v-else>
            <div class="item">
              <div class="img item-flex-center">
                <img
                  v-if="refundData.status == -1"
                  src="https://artimg2.artart.cn/image/20220224/da9deac23d61bab6c7fbb2357d464430.png"
                  alt=""
                >
                <img
                  v-else-if="refundData.status != 0"
                  src="https://artimg2.artart.cn/image/20220223/f50d059d34dc07c570052bdc3c6b9fc6.png"
                  alt=""
                >
                <img
                  v-else
                  src="https://artimg2.artart.cn/image/20220223/f2f03d09822dd523f373a4bac08ea426.png"
                  alt=""
                >
              </div>
              <div class="txt">客服审核</div>
            </div>
            <div
              class="item"
              v-if="refundData.refund_type === 2"
            >
              <div class="img item-flex-center">
                <img
                  v-if="refundData.status >= 2 || refundData.status == -2"
                  src="https://artimg2.artart.cn/image/20220223/f50d059d34dc07c570052bdc3c6b9fc6.png"
                  alt=""
                >
                <img
                  v-else
                  src="https://artimg2.artart.cn/image/20220223/f2f03d09822dd523f373a4bac08ea426.png"
                  alt=""
                >
              </div>
              <div class="txt">客户发货</div>
            </div>
            <div
              class="item"
              v-if="refundData.refund_type === 2"
            >
              <div class="img item-flex-center">
                <img
                  v-if="refundData.status >= 3 || refundData.status == -2"
                  src="https://artimg2.artart.cn/image/20220223/f50d059d34dc07c570052bdc3c6b9fc6.png"
                  alt=""
                >
                <img
                  v-else
                  src="https://artimg2.artart.cn/image/20220223/f2f03d09822dd523f373a4bac08ea426.png"
                  alt=""
                >
              </div>
              <div class="txt">商家收货</div>
            </div>
          </template>
          <div class="item">
            <div class="bg-r"></div>
            <div class="img item-flex-center">
              <img
                v-if="refundData.status == -2"
                src="https://artimg2.artart.cn/image/20220224/da9deac23d61bab6c7fbb2357d464430.png"
                alt=""
              >
              <img
                v-else-if="refundData.status >= 3"
                src="https://artimg2.artart.cn/image/20220223/f50d059d34dc07c570052bdc3c6b9fc6.png"
                alt=""
              >
              <img
                v-else
                src="https://artimg2.artart.cn/image/20220223/f2f03d09822dd523f373a4bac08ea426.png"
                alt=""
              >
            </div>
            <div class="txt">闪电退款</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    refundData: [Object, String],
  },
  setup() {},
};
</script>

<style scoped lang="scss">
.home {
  background: #f7f7f7;
  .top-s {
    padding-top: 32px;
  }
  .status {
    margin: 0 32px 32px 32px;
    padding: 32px;
    background: #fff;
    border-radius: 16px;

    .status-t {
      text-align: center;
      font-size: 32px;
    }

    .status-prop {
      margin-top: 16px;
      text-align: center;
      padding: 0 32px;
      color: #fb7575;
      font-size: 24px;
    }

    .status-txt {
      margin-top: 28px;
      position: relative;
      height: 90px;

      .bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 22px;
        background: #eee;
        height: 2px;
      }

      .content {
        height: 90px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        z-index: 10;
      }
      .item {
        flex: 1;
        position: relative;

        .bg-l,
        .bg-r {
          position: absolute;
          top: 0;
          background: #fff;
          z-index: 1;
          height: 32px;
        }

        .bg-l {
          left: 0;
          right: 50%;
        }

        .bg-r {
          right: 0;
          left: 50%;
        }

        img {
          background: #fff;
          width: 32px;
          height: 32px;
          display: block;
          position: relative;
          z-index: 100;
        }

        .txt {
          font-size: 20px;
          color: #999;
          margin-top: 16px;
          text-align: center;
        }
      }
    }
  }
}
</style>