<template>
  <div
    class="home"
    v-if="refundData"
  >
    <RefundStatus :refundData="refundData" />
    <div
      class="common-title"
      v-if="refundData.status > 1 && refundData.mer_delivery_user && refundData.mer_delivery_phone && refundData.mer_delivery_address"
    >售后退货地址</div>
    <div
      class="express-view"
      v-if="refundData.status > 1"
    >
      <div class="express-view-center">
        <div class="flex justify-b ft14 fw500">
          <div class="address-name flex-one">{{ refundData.mer_delivery_user }}</div>
          <div class="address-phone">{{ refundData.mer_delivery_phone }}</div>
        </div>
        <div class="flex justify-b ft12 mt8">
          <div class="address flex-one">{{ refundData.mer_delivery_address }}</div>
        </div>
      </div>
    </div>
    <div
      class="refund-product"
      v-if="refundData.status == 1"
    >
      <div class="common-title">填写运单号</div>
      <div class="waybill">
        <div
          class="item flex align-items-c"
          @click="showChangedeliveryAction"
        >
          <div class="l">物流公司</div>
          <div class="c">
            <div :class="{ 'color-gray': !delivery_name }">{{ delivery_name ? delivery_name : '请选择物流公司' }}</div>
          </div>
          <div class="r flex align-items-c">
            <img
              src="https://artimg2.artart.cn/image/20220219/86c43e7fab3564d61750317942dc0d83.png"
              alt=""
            >
          </div>
        </div>
        <div class="item flex">
          <div class="l">运单号码</div>
          <div class="c">
            <div class="inp">
              <input v-model="delivery_no">
            </div>
          </div>
        </div>
        <div class="item flex">
          <div class="l"></div>
          <div class="c">
            <div
              class="btn"
              :style="rightBtnStyle"
              @click="saveDeliveryAction"
            >提交</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="logistics"
      v-if="refundData.status >= 2 && refundData.delivery_name && refundData.delivery_no"
    >
      <div class="common-title">物流信息</div>
      <LogisticsInfo
        :express="express"
        :name="refundData.delivery_name"
        :id="refundData.delivery_no"
      />
    </div>
    <div class="refund-product">
      <div class="common-title">售后货品信息</div>
      <div>
        <ProductView :orderProduct="refundData.refundProduct" />
      </div>
    </div>
    <div class="refund-product">
      <div class="common-title">售后详情</div>
      <div class="refund-detail">
        <div class="item flex justify-b">
          <div class="l">退款金额</div>
          <div class="r fw500" style="color: #FB7575"><template v-if="merchantInfo">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}</template>{{refundData.refund_price}}</div>
        </div>
        <div class="item flex justify-b">
          <div class="l">服务单号</div>
          <div class="r">{{refundData.refund_order_sn}}</div>
        </div>
        <div class="item flex justify-b">
          <div class="l">申请时间</div>
          <div class="r">{{refundData.create_time}}</div>
        </div>
        <div class="item flex justify-b">
          <div class="l">服务类型</div>
          <div class="r">{{refundData.refund_type === 1 ? '仅退款' : '退货退款'}}</div>
        </div>
        <div class="item flex justify-b">
          <div class="l">申请原因</div>
          <div class="r">{{ refundData.refund_message }}</div>
        </div>
        <div class="item flex justify-b">
          <div class="l">补充说明</div>
          <div class="r">{{ refundData.mark }}</div>
        </div>
      </div>
    </div>
    <div
      class="change"
      v-if="showChangedelivery"
    >
      <van-picker
        style="width: 100%;"
        title="选择物流公司"
        :item-height="70"
        :default-index="delivery_name_index"
        :columns="deliveryArray"
        :columns-field-names="{ text: 'label' }"
        @confirm="changeOk"
      />
    </div>
    <div class="btns">
      <div
        class="btn"
        v-if="refundData.status >= 0 && refundData.status <= 1"
        :style="rightBtnStyle"
        @click="clearRefundAction"
      >取消售后</div>
      <div
        class="btn"
        :style="leftBtnStyle"
      >联系客服</div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Toast, Picker, Dialog } from "vant";
import RefundStatus from "./modules/RefundStatus.vue";
import LogisticsInfo from "./modules/LogisticsInfo.vue";
import ProductView from "./modules/ProductView.vue";
import {
  refundDetail,
  deliverGoods,
  cancelRefund,
  expressRefundInfo,
} from "@/api/order";
import { expressList } from "@/api/system";

export default {
  components: {
    RefundStatus,
    LogisticsInfo,
    ProductView,
    [Picker.name]: Picker,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const id = ref("");
    const refundData = ref("");

    const delivery_name = ref("");
    const delivery_name_index = ref(0);
    const delivery_no = ref("");

    const showChangedelivery = ref(false);
    const deliveryArray = ref([]);

    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });

    // 左按钮样式
    const leftBtnStyle = computed(() => {
      return store.getters.left_button_style
        ? store.getters.left_button_style
        : {};
    });

    const merchantInfo = computed(() => {
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    onBeforeMount(() => {
      id.value = route.query.id;
      loadDetail();
      expressList()
        .then((res) => {
          deliveryArray.value = res.data;
        })
        .catch((message) => Toast(message));
    });

    const loadDetail = () => {
      refundDetail(id.value)
        .then((res) => {
          refundData.value = res.data;
          if (res.data.delivery_name && res.data.delivery_no) {
            loadExpressInfo();
          }
        })
        .catch((message) => Toast(message));
    };

    const express = ref([]); // 物流信息
    const loadExpressInfo = () => {
      expressRefundInfo(id.value)
        .then((res) => {
          express.value = res.data.express || [];
        })
        .catch((message) => Toast(message));
    };

    const showChangedeliveryAction = () => {
      showChangedelivery.value = true;
    };

    const changeOk = (data, index) => {
      delivery_name_index.value = index;
      delivery_name.value = data.label;
      showChangedelivery.value = false;
    };

    let lock = false;
    const saveDeliveryAction = () => {
      if (!delivery_name.value) {
        Toast("请选择物流公司");
        return false;
      }
      if (!delivery_no.value) {
        Toast("请输入运单号码");
        return false;
      }

      if (lock) return false;
      lock = true;
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      deliverGoods(id.value, {
        delivery_name: delivery_name.value,
        delivery_no: delivery_no.value,
      })
        .then(() => {
          Toast.clear();
          Toast("操作成功");
          loadDetail();
        })
        .catch((message) => {
          Toast.clear();
          Toast(message);
          lock = false;
        });
    };

    const clearRefundAction = () => {
      Dialog.confirm({
        title: "确定取消售后吗？",
      }).then(() => {
        if (lock) return false;
        lock = true;
        Toast.loading({
          forbidClick: true,
          duration: 0,
        });

        cancelRefund(id.value)
          .then(() => {
            loadDetail();
            Toast.clear();
            Toast("操作成功");
            lock = false;
          })
          .catch((message) => {
            Toast.clear();
            Toast(message);
            lock = false;
          });
      });
    };

    return {
      refundData,
      delivery_name,
      delivery_name_index,
      delivery_no,
      deliveryArray,
      showChangedelivery,
      rightBtnStyle,
      leftBtnStyle,
      express,
      merchantInfo,

      showChangedeliveryAction,
      changeOk,
      saveDeliveryAction,
      clearRefundAction,
    };
  },
};
</script>

<style scoped lang="scss">
.home {
  background: #f7f7f7;
  .logistics {
    margin-top: 32px;
    background: #fff;
  }

  .common-title {
    padding-top: 32px;
    font-size: 28px;
    font-weight: 500;
    padding-left: 32px;
    color: #000;
  }

  .mt32 {
    margin-top: 32px;
  }

  .refund-detail {
    padding: 32px 32px 0 32px;
    background: #fff;
    border-radius: 8px;
    font-size: 28px;

    .item {
      padding-bottom: 32px;
    }

    .l {
      color: #666;
    }

    .r {
      color: #000;
      font-weight: 400;

      &.black {
        color: #000;
      }
    }
  }

  .refund-product {
    background: #fff;
    margin-top: 32px;
  }

  .waybill {
    margin: 32px;
    padding: 16px 32px;
    background: #fff;
    border-radius: 8px;

    .item {
      padding: 16px 0;
      .l {
        width: 160px;
        font-weight: 500;
        display: flex;
        align-items: center;
      }

      .c {
        flex: 1;

        .chiose {
          display: flex;
          align-items: center;
          margin-right: 20px;

          img {
            width: 48px;
            height: 48px;
            margin-right: 8px;
            display: block;
          }
        }

        .inp {
          // height: 64px;
          border: 2px solid #ddd;
          box-sizing: border-box;
          border-radius: 8px;
          padding: 0 20px;
          font-weight: 500;
          line-height: 60px;

          input {
            width: 100%;
            border: none;
            background: none;
            line-height: 60px;
          }
        }

        .btn {
          width: 120px;
          height: 54px;
          line-height: 54px;
          text-align: center;
        }
      }
    }
  }
}

.change {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
}

.btns {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 64px;
  background: #fff;
  z-index: 10;
  display: flex;
  align-items: center;

  .btn {
    margin-top: 20px;
    line-height: 60px;
    height: 60px;
    width: 140px;
    text-align: center;
    margin-left: 32px;
    font-size: 26px !important;
  }
}
.express-view {
  margin: 32px;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 22px 32px;
  position: relative;
  z-index: 10;

  .express-view-center {
    border-radius: 8px;

    .mt8 {
      margin-top: 8px;
    }

    .address {
      color: #999;
    }
    .address-edit {
      color: #347ff4;
    }
  }

  .express-view-bottom {
    margin-top: 32px;
    margin-bottom: 10px;
    color: #666;
    font-size: 28px;
  }
}
</style>